import { isBrowser } from "@local/utils"
import Cookies from "js-cookie"

export const getTrackingCookies = () => {
  const channelId = process.env.NEXT_PUBLIC_CHANNEL_ID as string
  const chills = isBrowser()
    ? (window.$ch ??
      window.$r?.chills ??
      new URL(window.location.href).searchParams.get("ch"))
    : (Cookies.get("chills") ?? Cookies.get("chills_s"))
  const exponeaId = Cookies.get("__exponea_etc__")
  const fbc = Cookies.get("_fbc")
  const fbp = Cookies.get("_fbp")
  const ga = Cookies.get("_ga")
  const gid = Cookies.get("_gid")
  const googleClickId = Cookies.get("_gcl_aw")
  const gtmUpi = Cookies.get("gtm_upi")
  const ip = Cookies.get("pie") ? atob(Cookies.get("pie") as string) : null
  const pinterestClick = Cookies.get("_epik")
  const rumbleClickId = Cookies.get("_raclid")
  const sessionId = isBrowser() ? window.$r?.sessionId : null
  const tikTokClickId = Cookies.get("ttclid")
  const vibes = isBrowser()
    ? (window.$vb ??
      window.$r?.vibes ??
      new URL(window.location.href).searchParams.get("vb"))
    : null
  const vvv = Cookies.get("vvvtwo")

  return {
    channelId,
    chills,
    exponeaId,
    fbc,
    fbp,
    ga,
    gid,
    googleClickId,
    gtmUpi,
    ip,
    pinterestClick,
    rumbleClickId,
    sessionId,
    tikTokClickId,
    vibes,
    vvv,
  }
}
