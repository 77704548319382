import type { PayloadLocale } from "./types"
import { Currency } from "./types"

export interface CountryData {
  anchorOnly?: boolean
  currencyCode: Currency
  currencySymbol: string
  dir: "ltr" | "rtl"
  locale: PayloadLocale
  priceDecimals: number
}

export const countriesData = new Map<string, CountryData>([
  /*
  [
    "ae",
    {
      anchorOnly: true,
      currencyCode: Currency.AED,
      currencySymbol: "د.إ",
      dir: "rtl",
      locale: "ar",
      priceDecimals: 0,
    },
  ],
  */
  [
    "au",
    {
      currencyCode: Currency.AUD,
      currencySymbol: "AU$",
      dir: "ltr",
      locale: "en",
      priceDecimals: 2,
    },
  ],
  [
    "ca",
    {
      currencyCode: Currency.CAD,
      currencySymbol: "CA$",
      dir: "ltr",
      locale: "en",
      priceDecimals: 2,
    },
  ],
  [
    "ch",
    {
      currencyCode: Currency.CHF,
      currencySymbol: "CHF",
      dir: "ltr",
      locale: "en",
      priceDecimals: 2,
    },
  ],
  [
    "eu",
    {
      currencyCode: Currency.EUR,
      currencySymbol: "€",
      dir: "ltr",
      locale: "en",
      priceDecimals: 2,
    },
  ],
  // [
  //   "de",
  //   {
  //     anchorOnly: true,
  //     currencyCode: Currency.EUR,
  //     currencySymbol: "€",
  //     dir: "ltr",
  //     locale: "de",
  //     priceDecimals: 2,
  //   },
  // ],
  [
    "fr",
    {
      anchorOnly: true,
      currencyCode: Currency.EUR,
      currencySymbol: "€",
      dir: "ltr",
      locale: "en",
      priceDecimals: 2,
    },
  ],
  [
    "gb",
    {
      currencyCode: Currency.GBP,
      currencySymbol: "£",
      dir: "ltr",
      locale: "en",
      priceDecimals: 2,
    },
  ],
  // [
  //   "hk",
  //   {
  //     currencyCode: Currency.HKD,
  //     currencySymbol: "HKD",
  //     dir: "ltr",
  //     locale: "zh",
  //     priceDecimals: 0,
  //   },
  // ],
  [
    "ie",
    {
      anchorOnly: true,
      currencyCode: Currency.EUR,
      currencySymbol: "€",
      dir: "ltr",
      locale: "en",
      priceDecimals: 2,
    },
  ],
  [
    "nz",
    {
      currencyCode: Currency.NZD,
      currencySymbol: "NZ$",
      dir: "ltr",
      locale: "en",
      priceDecimals: 2,
    },
  ],
  // [
  //   "sa",
  //   {
  //     anchorOnly: true,
  //     currencyCode: Currency.SAR,
  //     currencySymbol: "ر.س",
  //     dir: "rtl",
  //     locale: "ar",
  //     priceDecimals: 0,
  //   },
  // ],
  // [
  //   "sg",
  //   {
  //     anchorOnly: true,
  //     currencyCode: Currency.SGD,
  //     currencySymbol: "S$",
  //     dir: "ltr",
  //     locale: "en",
  //     priceDecimals: 2,
  //   },
  // ],
  // [
  //   "tw",
  //   {
  //     currencyCode: Currency.TWD,
  //     currencySymbol: "NT$",
  //     dir: "ltr",
  //     locale: "zh",
  //     priceDecimals: 0,
  //   },
  // ],
  [
    "us",
    {
      currencyCode: Currency.USD,
      currencySymbol: "$",
      dir: "ltr",
      locale: "en",
      priceDecimals: 2,
    },
  ],
])

export const countriesLabels = [
  ["au", "Australia (AU$)"],
  ["ca", "Canada (CA$)"],
  ["eu", "Europe (€)"],
  ["gb", "United Kingdom (£)"],
  // ["hk", "Hong Kong (HKD)"],
  ["nz", "New Zealand (NZ$)"],
  ["ch", "Switzerland (CHF)"],
  // ["tw", "Taiwan (NT$)"],
  ["us", "United States ($)"],
  // ["ae", "United Arab Emirates (AED د.إ) "],
  // ["de", "Deutschland (€)"],
  // ["sa", "Saudi Arabia (SAR ر.س)"],
  // ["sg", "Singapore (S$)"],
]
