import { AvailableLanguages } from "./types"

const isAnchor = !["baerskintactical", "hamstore", "cozistore"].includes(
  process.env.NEXT_PUBLIC_CHANNEL_ID as string
)

export const getLocaleFromBrowser = (): string => {
  const { languages, language } = navigator

  if (languages) {
    for (const lang of languages) {
      const langCode = lang.substr(0, 2).toLowerCase()

      if (AvailableLanguages.includes(langCode as any) && isAnchor) {
        return langCode
      }
    }
  }

  const langCode = language.substr(0, 2).toLowerCase()

  if (AvailableLanguages.includes(langCode as any) && isAnchor) {
    return langCode
  }

  return "en"
}
