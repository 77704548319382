export const overrideStoreSpecialCountries = (country: string) => {
  const channelId = process.env.NEXT_PUBLIC_CHANNEL_ID as string
  const IS_STORE = ["baerskintactical", "hamstore", "cozistore"].includes(
    channelId
  )

  if (IS_STORE && ["tw", "ae", "sa", "hk"].includes(country)) {
    return "us"
  }

  return country
}
