"use client";

import { useId, useRef, useState } from "react";
import { Transition } from "@local/ui/src/Transition";
import { classNames } from "@local/utils/src/classNames";
import { useOutsideClickCapture } from "@local/utils/src/useOutsideClickCapture";
const CheckIcon = ({
  className
}: {
  className?: string;
}) => <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true" className={className} data-sentry-element="svg" data-sentry-component="CheckIcon" data-sentry-source-file="DropDown.tsx">
    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" data-sentry-element="path" data-sentry-source-file="DropDown.tsx" />
  </svg>;
const ChevronUpDownIcon = ({
  className
}: {
  className?: string;
}) => <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true" className={className} data-sentry-element="svg" data-sentry-component="ChevronUpDownIcon" data-sentry-source-file="DropDown.tsx">
    <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9" data-sentry-element="path" data-sentry-source-file="DropDown.tsx" />
  </svg>;
export interface DropDownValueType {
  value: number | string;
  label: string;
  startIcon?: JSX.Element;
  outerLabel?: string | JSX.Element;
}
interface Props {
  selected: string | number;
  onChange: (e: any) => void;
  options: DropDownValueType[];
  positionTop?: boolean;
  className?: string;
  wrapperClassName?: string;
  wfull?: boolean;
  id?: string;
  bigLabel?: boolean;
  dropdownClasses?: string;
  disabled?: boolean;
}
export const DropDown = ({
  selected,
  onChange,
  options,
  positionTop = true,
  className,
  wrapperClassName = "",
  wfull,
  id,
  bigLabel,
  dropdownClasses,
  disabled
}: Props) => {
  const [isOpen, toggleOpen] = useState(false);
  const uniqueId = useId;
  const wrapperRef = useRef(null);
  useOutsideClickCapture(wrapperRef, () => toggleOpen(false));
  const label = options.find(item => item.value === selected);
  return <div className={classNames("relative", wfull ? "w-full" : "w-fit", wrapperClassName)} ref={wrapperRef} id={id} data-sentry-component="DropDown" data-sentry-source-file="DropDown.tsx">
      <div className={classNames("dropdown-trigger sm:text-md relative cursor-default bg-white pl-3 pr-10 text-black text-sm  text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300", className ? className : "")} onClick={() => {
      if (!disabled) {
        toggleOpen(!isOpen);
      }
    }}>
        <span className={classNames(bigLabel ? "!text-2xl" : "", disabled ? "opacity-60 grayscale" : "")}>
          {label?.outerLabel ? label?.outerLabel : label?.label ?? ""}
        </span>
        <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
          <ChevronUpDownIcon className="w-5 h-5 text-gray-400" aria-hidden="true" data-sentry-element="ChevronUpDownIcon" data-sentry-source-file="DropDown.tsx" />
        </span>
      </div>
      <div className="absolute z-[100]" style={positionTop ? {
      bottom: "2.5rem"
    } : {
      top: "100%"
    }}>
        <Transition show={isOpen} enter="transition ease-out duration-100" enterFrom="scale-95 transform opacity-0" enterTo="scale-100 transform opacity-100" leave="transition ease-out duration-100" leaveFrom="scale-100 transform opacity-100" leaveTo="scale-95 transform opacity-0" data-sentry-element="Transition" data-sentry-source-file="DropDown.tsx">
          <ul className={classNames("z-50 py-1 mt-1 overflow-auto text-base list-none bg-white rounded-md shadow-lg sm:text-md bottom-full !max-h-80 ring-1 ring-black ring-opacity-5 focus:outline-none", dropdownClasses ? dropdownClasses : "")}>
            {/* w-fit min-w-max */}
            {options.map(item => <li key={`li-select-option-${item.value}-${uniqueId}`} value={item.value} className={classNames("dropdown-item relative cursor-pointer py-2 px-6 pl-10 text-gray-900 list-none", selected === item.value ? "bg-amber-100 text-amber-900 hover:bg-amber-200" : "text-gray-900 hover:bg-gray-100")} onClick={() => {
            onChange(item.value);
            toggleOpen(false);
          }}>
                <div className="flex gap-2 items-center">
                  <span>
                    {item.startIcon && <span className="mr-2">{item.startIcon}</span>}
                    {item.label}
                  </span>
                </div>
                {selected === item.value ? <span className="absolute inset-y-0 bottom-0 left-0 flex items-center pl-3 text-amber-600">
                    <CheckIcon className="w-5 h-5" aria-hidden="true" />
                  </span> : ""}
              </li>)}
          </ul>
        </Transition>
      </div>
    </div>;
};
export default DropDown;