/**
 * @description - checks if a locale is inside or outside the EU zone
 * @param country - country ISO Alpha‑2 code string i.e. "FR", "US", etc
 * @return true if the country is inside EU zone
 * @example isCountryInsideEU("FR") => true
 */
export const isCountryInsideEU = (country: string) => {
  const countriesInsideEU = [
    "AT",
    "BE",
    "BG",
    "CY",
    "CZ",
    "DK",
    "EE",
    "ES",
    "FI",
    "FR",
    "GR",
    "HR",
    "HU",
    "IE",
    "IT",
    "LT",
    "LU",
    "LV",
    "MT",
    "NL",
    "PL",
    "PT",
    "RO",
    "SE",
    "SI",
    "SK",
    "TR",
  ]
  return countriesInsideEU.includes(country.toUpperCase())
}
