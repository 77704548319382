/**
 * Function to safely parse JSON strings and return the parsed object.
 * If the input is already an object, it returns the input as is.
 * Throws an error if the input is neither a string nor an object.
 * @param {string|object} input - The input to parse. Expected to be a JSON string or an object.
 * @returns {object} - The parsed JSON object or the input object if the input is already an object.
 * @throws {Error} - Throws an error if the input is not a string or an object, or if JSON parsing fails.
 */
export const safeJSONParse = <T>(
  input: string | object | unknown
): T | null => {
  if (typeof input === "string") {
    try {
      return JSON.parse(input) as T
    } catch (error) {
      console.error("Failed to parse JSON:", input, error)
      return null
    }
  } else if (typeof input === "object" && input !== null) {
    return input as T
  } else {
    console.error(
      "Invalid input type for JSON parsing. Expected a string or an object.",
      input
    )
    return null
  }
}
