import { fetchRetry } from "./fetchRetry"

interface FetchApiInput {
  endpoint: string
  method: "GET" | "POST"
  data?: object
  options?: {
    revalidate?: false | 0 | number
    skipError?: boolean
    cache?: "force-cache" | "no-store"
  }
  retries?: number
  signal?: AbortSignal
}

/**
 * Fetches a data request to an API endpoint
 *
 * @param endpoint - The endpoint you are going to call
 * @param data - Any data you want to pass, like graphQL variables or other parameters
 * @returns The data outputted by that endpoint
 */
export const fetchApi = async <T>({
  data,
  endpoint,
  method = "POST",
  options,
  retries = 1,
  signal,
}: FetchApiInput): Promise<T | null> => {
  try {
    const initBody = {
      body: data ? JSON.stringify(data) : undefined,
      headers: {
        "content-type": "application/json",
      },
      method,
      next: {
        cache: options?.cache,
        revalidate: options?.revalidate,
      },
      signal,
    }
    const deploymentUrl = process.env.NEXT_PUBLIC_APP_URL || ""
    const endpointWithPrevix = `${deploymentUrl}${endpoint}`

    const res = await fetchRetry(endpointWithPrevix, initBody, retries)

    if (!res) {
      throw new Error("Could not fetch data")
    }

    const json = await res.json()

    if (json.error && !options?.skipError) {
      const error = new Error(JSON.stringify(json.error))
      console.error(error)
      throw error
    }

    return json
  } catch (error) {
    console.error(error)
    return null
  }
}
