import { Currency } from "./types"

type ShippingData = {
  threshold: number
  currency: Currency
  price: number
}

const hamData: Array<ShippingData> = [
  {
    threshold: 10000,
    currency: Currency.USD,
    price: 495,
  },
  {
    threshold: 15000,
    currency: Currency.CAD,
    price: 995,
  },
  {
    threshold: 10000,
    currency: Currency.CHF,
    price: 495,
  },
  {
    threshold: 10000,
    currency: Currency.GBP,
    price: 495,
  },
  {
    threshold: 15000,
    currency: Currency.AUD,
    price: 995,
  },
  {
    threshold: 10000,
    currency: Currency.EUR,
    price: 495,
  },
  {
    threshold: 15000,
    currency: Currency.NZD,
    price: 995,
  },
]

const baerskinData: Array<ShippingData> = [
  {
    threshold: 11500,
    currency: Currency.USD,
    price: 795,
  },
  {
    threshold: 15500,
    currency: Currency.CAD,
    price: 995,
  },
  {
    threshold: 11500,
    currency: Currency.CHF,
    price: 795,
  },
  {
    threshold: 11500,
    currency: Currency.GBP,
    price: 795,
  },
  {
    threshold: 15500,
    currency: Currency.AUD,
    price: 1195,
  },
  {
    threshold: 11500,
    currency: Currency.EUR,
    price: 795,
  },
  {
    threshold: 15500,
    currency: Currency.NZD,
    price: 1195,
  },
]

const coziData: Array<ShippingData> = [
  {
    threshold: 5000,
    currency: Currency.USD,
    price: 495,
  },
  {
    threshold: 7500,
    currency: Currency.CAD,
    price: 995,
  },
  {
    threshold: 5000,
    currency: Currency.CHF,
    price: 495,
  },
  {
    threshold: 5000,
    currency: Currency.GBP,
    price: 495,
  },
  {
    threshold: 7500,
    currency: Currency.AUD,
    price: 995,
  },
  {
    threshold: 5000,
    currency: Currency.EUR,
    price: 495,
  },
  {
    threshold: 7500,
    currency: Currency.NZD,
    price: 995,
  },
]

const shippingData: Array<[string, Array<ShippingData>]> = [
  ["baerskintactical", baerskinData],
  ["cozistore", coziData],
  ["hamstore", hamData],
]

export const shippingMap = new Map(shippingData.map((obj) => [obj[0], obj[1]]))

export const getShippingThreshold = (currency?: Currency | null) => {
  const channelId = process.env.NEXT_PUBLIC_CHANNEL_ID as string
  const channelData = shippingMap.get(channelId)
  const currencyToCheck = currency || "USD"

  priceChecker: {
    // Skip all checking if the store is not in the rules
    if (!channelData) {
      break priceChecker
    }

    // Check if a price in the currency exists
    let itemData = channelData.find((item) => item.currency === currencyToCheck)

    // Fallback to USD if no currency is found
    if (!channelData.find((item) => item.currency === currencyToCheck)) {
      channelData.find((item) => item.currency === Currency.USD)
    }

    // Check if cart is above threshold
    return itemData?.threshold as number
  }

  return 0
}

export const getShippingCost = (subtotal: number, currency: Currency) => {
  const channelId = process.env.NEXT_PUBLIC_CHANNEL_ID as string
  const channelData = shippingMap.get(channelId)

  priceChecker: {
    // Skip all checking if the store is not in the rules
    if (!channelData) {
      break priceChecker
    }

    // Check if a price in the currency exists
    let itemData = channelData.find((item) => item.currency === currency)

    // Fallback to USD if no currency is found
    if (!channelData.find((item) => item.currency === currency)) {
      itemData = channelData.find((item) => item.currency === Currency.USD)
    }

    // Check if cart is above threshold
    if (subtotal < (itemData?.threshold as number)) {
      return itemData?.price as number
    }
  }

  return 0
}
