export const sendBacon = async (endpoint: string, blob: Blob) => {
  try {
    if (
      typeof navigator !== "undefined" &&
      typeof navigator.sendBeacon === "function"
    ) {
      return navigator.sendBeacon(endpoint, blob)
    }
  } catch (error) {
    console.error(error)
  }

  try {
    if (typeof fetch !== "undefined") {
      const req = await fetch(endpoint, {
        credentials: "include",
        headers: {
          "content-type": "text/plain",
        },
        method: "POST",
        body: blob,
        keepalive: true,
      })
      return req.ok
    }
  } catch (error) {
    console.error(error)
  }

  // Final fallback to XMLHttpRequest
  const xhr = new XMLHttpRequest()
  xhr.open("POST", endpoint, true)
  xhr.withCredentials = true
  xhr.setRequestHeader("Content-Type", "text/plain")
  xhr.send(blob)
}

export const sendBeacon = sendBacon
