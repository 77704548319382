export const getMainDomain = (url: string) => {
  let domain = new URL(url).hostname
  let parts = domain.split(".").reverse()
  let tld = parts[0]
  let main = parts[1]

  if (parts.length > 2 && parts[1].length === 2) {
    tld = parts[1] + "." + tld
    main = parts[2]
  }

  if (url.indexOf("localhost") > -1) {
    return "localhost"
  }

  return main + "." + tld
}
