"use client"

import type { Cart, CartItem } from "@local/cart/src/types"

export const clearRandomIdsFromCartBundles = (cart: Cart) => {
  const items: CartItem[] = []

  cart.items.forEach((item) => {
    if (item.bundleItems && item.bundleItems.length > 0) {
      items.push({
        ...item,
        variantId: undefined,
        variantName: undefined,
      })
    } else {
      items.push(item)
    }
  })

  return {
    ...cart,
    items,
  }
}
