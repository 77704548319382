"use client"

import { isBrowser } from "@local/utils"
import Cookies from "js-cookie"

export const cleanStaleCartId = (currentId?: string | null) => {
  if (
    isBrowser() &&
    window &&
    window.localStorage &&
    window.localStorage.hasOwnProperty("getItem")
  ) {
    const order = window.localStorage.getItem("order")
    if (order) {
      const parsedOrder = JSON.parse(order)
      if (parsedOrder && parsedOrder.orders && parsedOrder.orders.length >= 1) {
        if (currentId) {
          parsedOrder.orders.find((order: any) => {
            if (order.uid === currentId) {
              Cookies.remove("div-cart")
              Cookies.remove("div-cart-short")
              return true
            }
            return false
          })
        }
      }
    }
    return false
  }
}
