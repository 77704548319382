/**
 * Fetches a data request tand retries a few times
 *
 * @returns REQUEST
 */
export const fetchRetry = async (
  url: string | URL,
  options: RequestInit,
  n: number
) => {
  for (let i = 0; i < n; i++) {
    try {
      const req = await fetch(url, {
        ...options,
        signal: options.signal,
        // signal: options.signal || AbortSignal.timeout(30000),
      })
      if (req && req.ok) {
        return req
      }
    } catch (err) {
      const isLastAttempt = i + 1 === n
      if (isLastAttempt) throw err
    }
  }
}
