/**
 * Checks if it's in a browser or server-side environment
 *
 * @returns a boolean. `true` if it's browser, `false` if it's SSR
 */
export const isBrowser = () => {
  return typeof window === "undefined" ? false : true
}

// THIS THROWS HYDRATION PROBLEMS WHEN USED IN RETURN STATEMENTS!
// G U A R A N T E E D ! ! ! !
