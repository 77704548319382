/**
 * This is needed to sanitise base64 characters with unicode issues
 * @param bytes
 * @returns encoded base64 string
 */
export const bytesToBase64 = (bytes: Uint8Array) => {
  const binString = Array.from(bytes, (byte) =>
    String.fromCodePoint(byte)
  ).join("")
  return btoa(binString)
}

/**
 * This is needed to sanitise base64 characters with unicode issues
 * @param data
 * @returns encoded base64 string
 */
export const stringToBase64 = (data: string) => {
  const encoded = bytesToBase64(new TextEncoder().encode(data))
  return encoded
}

/**
 * This is needed to sanitise base64 characters with unicode issues
 * @param data
 * @returns encoded base64 string
 */
export const objectToBase64 = (data: object) => {
  const text = JSON.stringify(data, null, 2)
  const encoded = stringToBase64(text)
  return encoded
}

/**
 * This encodes to Base64, AND shifts the characters by X numbers
 * @param data
 * @param offset
 * @returns encoded base64 string
 */
export const objectToShiftedBase64 = (data: object, offset: number) => {
  const encoded = objectToBase64(data)

  if (encoded.length <= offset) {
    return encoded
  }

  // Get the last 20 characters
  const finalData = encoded.slice(-offset)

  // Remove the last 20 characters from the original string
  const restOfString = encoded.slice(0, -offset)

  // Concatenate the last 20 characters to the beginning of the original string minus the last 20 characters
  return finalData + restOfString
}

/**
 * This checks if the string is base64
 * @param str: string
 * @returns boolean
 */
export const detectBase64 = (str: string) => {
  const base64RegExp =
    /^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{4})$/
  const isBase64 = base64RegExp.test(str)
  return isBase64
}

/**
 * Decodes base64 while retaining weird broken characters
 * @param str: string
 * @returns json
 */
export const decodeBase64 = <T extends object>(body: string) => {
  const bodyData = detectBase64(body) ? atob(body) : body
  const cleanedParse = bodyData.replaceAll("Ã", "Æ")
  const finalParse = JSON.parse(cleanedParse)
  return finalParse as T
}

/**
 * Decodes base64 while retaining weird broken characters
 * @param str: string
 * @returns json
 */
export const decodeShiftedBase64 = <T extends object>(
  body: string,
  offset: number
) => {
  if (body.length <= offset) {
    return JSON.parse(body) as T
  }

  // Get the last 20 characters
  const finalData = body.slice(offset)

  // Remove the last 20 characters from the original string
  const restOfString = body.slice(0, offset)

  // Concatenate the last 20 characters to the beginning of the original string minus the last 20 characters
  const assembled = finalData + restOfString

  if (!detectBase64(assembled)) {
    return JSON.parse(assembled) as T
  }

  const bodyData = atob(assembled)
  const cleanedParse = bodyData.replaceAll("Ã", "Æ")
  const finalParse = JSON.parse(cleanedParse)
  return finalParse as T
}
