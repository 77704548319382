"use client"
import { useEffect, useState } from "react"

const useDetectDevice = () => {
  const [device, setDevice] = useState("")
  const [browser, setBrowser] = useState("")

  useEffect(() => {
    const userAgent = typeof window !== "undefined" ? navigator.userAgent : ""
    if (Boolean(userAgent.match(/Android/i))) {
      setDevice("Android")
    } else if (Boolean(userAgent.match(/iPhone|iPad|iPod/i))) {
      setDevice("iOS")
    } else {
      setDevice("unknown")
    }

    if (Boolean(userAgent.match(/Safari/i))) {
      setBrowser("Safari")
    } else if (Boolean(userAgent.match(/Chrome/i))) {
      setBrowser("Chrome")
    } else if (Boolean(userAgent.match(/Firefox/i))) {
      setBrowser("Firefox")
    } else if (Boolean(userAgent.match(/Edge/i))) {
      setBrowser("Edge")
    } else {
      setBrowser("unknown")
    }
  }, [])

  return {
    // device,
    // browser,
    isIos: device === "iOS",
    isAndroid: device === "Android",
    isMobile: device === "Android" || device === "iOS",
    isSafari: browser === "Safari",
    isChrome: browser === "Chrome",
    isFirefox: browser === "Firefox",
    isEdge: browser === "Edge",
  }
}

export default useDetectDevice
