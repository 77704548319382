import Cookies from "js-cookie"
import { customAlphabet } from "nanoid"
import { CartEventInput } from "./api/event"
import { objectToShiftedBase64 } from "@local/utils/src/safeBase64"
import { sendBacon } from "@local/utils/src/sendBeacon"
import { cleanStaleCartId } from "@local/utils"

export interface CartIdResponse {
  id: string
  result: boolean
}

/**
 * This is an extension of `createId` which generates and sets th cookies for the cart ids.
 * It also does verification on collision.
 * @param force
 * @returns
 */
export const setId = (force?: boolean) => {
  let didGenerateAnId = false

  // LONG ID
  let longId = Cookies.get("div-cart")
  const hasCleanedLongId = cleanStaleCartId(longId)

  if (hasCleanedLongId || !longId || force) {
    longId = customAlphabet("0123456789abcdefghijklmnopqrstuvwxyz", 24)()
    Cookies.set("div-cart", longId, { expires: 5, sameSite: "strict" })
    didGenerateAnId = true
  }

  // SHORT ID
  let shortId = Cookies.get("div-cart-short")
  const hasCleanedShortId = cleanStaleCartId(shortId)

  if (hasCleanedShortId || !shortId || force) {
    shortId = customAlphabet("123467890ABCDEFGHJKLMNPQRTUVWXYZ", 8)()
    Cookies.set("div-cart-short", shortId, { expires: 5, sameSite: "strict" })
    didGenerateAnId = true
  }

  // Check if the ID is unique if something was generated
  try {
    if (didGenerateAnId) {
      // Beam up an event to create the cart
      const data = {
        action: "init",
        channel:
          window && window.channel
            ? window.channel
            : process.env.NEXT_PUBLIC_CHANNEL_ID,
        contents: {},
        cookies: document.cookie,
        id: longId,
        operation: "create",
        snapshot: {},
        uid: shortId,
      } as CartEventInput

      const blob = new Blob([objectToShiftedBase64(data, 24)], {
        type: "text/plain",
      })
      sendBacon("/api/cart/event/", blob)
    }
    return {
      id: longId,
      uid: shortId,
    }
  } catch (error) {
    console.error(error)
  } finally {
    return {
      id: longId,
      uid: shortId,
    }
  }
}
