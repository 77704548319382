export enum Currency {
  "AUD" = "AUD",
  "CAD" = "CAD",
  "CHF" = "CHF",
  "EUR" = "EUR",
  "GBP" = "GBP",
  "NZD" = "NZD",
  "USD" = "USD",
}

export const AvailableCurrencies = [
  "AUD" as Currency.AUD,
  "CAD" as Currency.CAD,
  "EUR" as Currency.EUR,
  "GBP" as Currency.GBP,
  "NZD" as Currency.NZD,
] as const

export const AvailableLanguages = ["en"] as const

export type PayloadLocale = (typeof AvailableLanguages)[number]
