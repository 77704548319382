import { create } from "zustand"
import { persist, createJSONStorage } from "zustand/middleware"
import Cookies from "js-cookie"
import { Currency, PayloadLocale } from "./types"
import {
  getCountry,
  getCountryData,
  getCurrencySymbolByCurrencyCode,
} from "./i10n"
import { isCountryInsideEU } from "./isCountryInsideEU"
import { getLocaleFromBrowser } from "./getLocaleFromBrowser"
import * as Sentry from "@sentry/nextjs"
import { overrideStoreSpecialCountries } from "./overrideStoreSpecialCountries"
import { countriesData } from "./data"
import { getMainDomain } from "@local/utils/src/getMainDomain"

interface I10nState {
  country: string
  currencyCode?: Currency | null
  currencySymbol: string
  dir: "ltr" | "rtl"
  getCurrencyFromCookie: () => string | null
  getCountryFromCookie: () => string | null
  getLangFromStorage: () => string | null
  getRealCountry: () => string
  init: (
    country: string,
    overrideCurrency?: boolean
  ) => {
    currency: string
    country: string
  }
  isUSA: () => boolean
  locale: PayloadLocale
  priceDecimals: number
  overrideCurrency: (currencyCode: Currency) => void
}

export const useI10n = create<I10nState>()(
  persist(
    (set, get) => ({
      country: "us",
      currencySymbol: "$",
      currencyCode: null,
      dir: "ltr",
      getCurrencyFromCookie: () => {
        const biscuit = Cookies.get("div-currency")
        return biscuit ?? null
      },
      getCountryFromCookie: () => {
        const biscuit = Cookies.get("country")
        return biscuit ?? null
      },
      getLangFromStorage: () => {
        const biscuit = Cookies.get("div-lang")
          ? (Cookies.get("div-lang") as PayloadLocale)
          : null

        return biscuit
      },
      getRealCountry: () => {
        const localCountry = get().country
        const realCountry =
          localCountry && localCountry.toUpperCase() !== "EU"
            ? localCountry.toUpperCase()
            : window.$r?.geo?.country
              ? (window.$r?.geo?.country.toUpperCase() as string)
              : "US"
        return realCountry
      },
      overrideCurrency: (currencyCode: Currency) => {
        const domain = getMainDomain(process.env.NEXT_PUBLIC_APP_URL as string)
        Cookies.set("div-currency", currencyCode, {
          expires: 365,
          domain,
        })
      },
      init: (country: string, overrideCurrency: boolean = false) => {
        let countryToSet = getCountry(country)
        let identifiedLocale: string | null = get().getLangFromStorage()

        // This is a temporary mess until we merge Dato into Payload CMS
        if (!identifiedLocale) {
          identifiedLocale = getLocaleFromBrowser()
          Cookies.set("div-lang", identifiedLocale, { expires: 365 })
        }

        if (isCountryInsideEU(country)) {
          countryToSet = "eu"
        } else {
          // Special mechanism to avoid issue in a few countries
          countryToSet = overrideStoreSpecialCountries(country)

          // Non existing country? Go back to the US!
          if (!countriesData.has(countryToSet)) {
            countryToSet = "us"
          }
        }

        const geo = getCountryData(countryToSet)

        let locale = geo?.locale
        let dir = geo?.dir
        const priceDecimals = geo?.priceDecimals

        const fromCookie = Cookies.get("div-currency")
        const currencyToUse = (
          !overrideCurrency && fromCookie ? fromCookie : geo?.currencyCode
        ) as Currency
        const domain = getMainDomain(process.env.NEXT_PUBLIC_APP_URL as string)

        Cookies.set("div-currency", currencyToUse, {
          expires: 365,
          domain,
        })

        const currencySymbol =
          getCurrencySymbolByCurrencyCode(currencyToUse) ?? geo?.currencySymbol
        // END OF MESS

        set(() => ({
          country: countryToSet,
          currencyCode: currencyToUse,
          currencySymbol,
          dir,
          locale,
          priceDecimals,
        }))

        if (overrideCurrency) {
          Cookies.set("country", countryToSet, { expires: 365, domain })
        }

        Sentry.setContext("i10n", {
          country: countryToSet,
          currencyCode: currencyToUse,
          currencySymbol,
          dir,
          locale,
          priceDecimals,
        })

        return {
          country: countryToSet,
          currency: currencyToUse,
        }
      },
      isUSA: () => {
        const currency = get().currencyCode
        const country = get().country

        return currency === "USD" && country == "us"
      },
      locale: "en",
      priceDecimals: 2,
    }),
    {
      name: "i10n",
      storage: createJSONStorage(() => localStorage),
      version: 3,
    }
  )
)
