export const cleanDomain = (domain: string) => {
  domain = domain.split("?")[0]
  if (domain.indexOf("://") > -1) domain = domain.split("://")[1]
  domain = domain.split("/")[0]
  const splitByDot = domain.split(".")
  if (domain.indexOf("co.uk") > -1 || domain.indexOf("com.br") > -1) {
    if (splitByDot.length > 2) {
      splitByDot.splice(0, splitByDot.length - 3)
      return splitByDot.join(".")
    }
  }
  if (splitByDot.length > 1) {
    splitByDot.splice(0, splitByDot.length - 2)
  }
  return splitByDot.join(".")
}
