import { customAlphabet } from "nanoid"

/**
 * This is a simple helper to generate both ids, does not set cookies or do anything, just generates
 * @returns id and uid
 */
export const createId = () => {
  const longId = customAlphabet("0123456789abcdefghijklmnopqrstuvwxyz", 24)()
  const shortId = customAlphabet("123467890ABCDEFGHJKLMNPQRTUVWXYZ", 8)()

  return {
    id: longId,
    uid: shortId,
  }
}
