import type { Order } from "@local/order/src/useOrder/types"
import { Payload } from "@local/payload-client/src/types"
import { StoreApi, UseBoundStore } from "zustand"
import { CartItem as OptionsManagerCartItem } from "@local/product-option-manager/types"

export enum Currency {
  "AUD" = "AUD",
  "CAD" = "CAD",
  "CHF" = "CHF",
  "EUR" = "EUR",
  "GBP" = "GBP",
  "NZD" = "NZD",
  "USD" = "USD",
}

export type Price = {
  min: number
  max?: number
  value: number
}

export type SingleCartItem = {
  isAddon?: boolean | null
  preOrder?: string | null
  price?: number | null
  productId: string
  productName: string
  quantity: number
  sku?: string | null
  slug: string
  type?: string
  variantId?: string | null
  variantName?: string | null
}

export type CartItem = SingleCartItem & {
  giftWrap?: string | null
  image?: string | null
  prices: Array<Price>
  bundleItems?: Array<SingleCartItem> | null
}

export type Total = {
  discount?: number | null
  giftCard?: number | null
  grandTotal: number
  shipping: number
  subTotal: number
  tax: number
  tip?: number | null
}

export type DiscountMethod = "percentage" | "amount"

export type Discount = {
  amount: number
  code: string
  method?: DiscountMethod
  min?: number | null
}

export interface BeamEventInput {
  action: string
  contents: object
  operation: "create" | "update" | "delete" | "track"
}

export interface AddCouponInput {
  code: string
  currency?: string | null
}

export interface ChangeQtyInput {
  amount: number
  currency: Currency
  index: number
  isAnchor?: boolean
  trackActionBuffered?:
    | UseBoundStore<StoreApi<any>>
    | ((name: string, data?: any) => void)
}

export interface BonusCartItem extends Payload.Product {
  levelKey: number
  giftAccepted: boolean
  cartItem?: OptionsManagerCartItem
  image?: string
}

export interface Cart {
  channel?: string | null | undefined
  coupon?: Discount | null
  createdAt?: Date
  error?: string | null
  giftCard?: Discount | null
  id?: string | null //Long ID, used for internal control
  items: Array<CartItem>
  totals: Total
  uid?: string | null //Short ID, used by customer to identify order
  updatedAt?: Date
  isUpsellCart?: boolean
  currency?: Currency | null
}
export interface ReplaceItems {
  channel?: string | null
  items: CartItem[]
  currency?: string | null
  isAnchor?: boolean
  noTrack?: boolean
  location?: string
  trackActionBuffered?:
    | UseBoundStore<StoreApi<any>>
    | ((name: string, data?: any) => void)
  isUpsell?: boolean
}

export interface AddToCart extends ReplaceItems {
  trackActionBuffered?:
    | UseBoundStore<StoreApi<any>>
    | ((name: string, data?: any) => void)
  isUpsell?: boolean
}

export interface ConvertToOrder {
  bonusItems?: BonusCartItem[]
  cb: (order: Order) => boolean
  currency?: Currency | null
  id?: string | null
  method?: string | null
  uid?: string | null
}

export interface CartState extends Cart {
  addCoupon: (input: AddCouponInput) => Promise<Cart>
  addGiftCard: (card: string) => Promise<Cart>
  addItems: (input: AddToCart) => Cart
  beamCartEvent: (input: BeamEventInput) => Promise<void>
  convertToOrder: (input: ConvertToOrder) => Promise<void>
  changeQuantity: (input: ChangeQtyInput) => Promise<Cart>
  getCount: () => number
  getUnfoldedItems: () => Array<CartItem>
  removeAnchorItem: (index: number, currency?: string) => Cart
  removeCoupon: (noTrack?: boolean) => Cart
  removeGiftCard: () => Cart
  removeItem: (index: number, currency?: string, isAnchor?: boolean) => Cart
  reset: (convert?: boolean | null, channel?: string) => Promise<Cart>
  restore: (input: Cart, currency?: Currency | null) => Promise<Cart>
  replaceItems: (input: ReplaceItems) => Cart
  setError: (input: string | null) => Cart
  setTip: (amount: number) => Cart
  validateChannel: (channel?: string | null | undefined) => boolean
}

export interface GiftCartState {
  bonusItems: BonusCartItem[]
  bonusLevel: number
  setBonusLevel: (bonusLevel: number) => void
  addBonusItems: (input: {
    items: Array<Payload.Product>
    level: number
  }) => void
  removeBonusItems: (bonusLevel: number) => void
  acceptGift: (
    levelKey: number,
    cartItem: OptionsManagerCartItem,
    image: string
  ) => void
  setGiftVariant: (
    levelKey: number,
    cartItem: OptionsManagerCartItem,
    image: string
  ) => void
  setGiftImage: (levelKey: number, image: string) => void
  clearGiftCart: () => void
}

// GIFTS
export type GiftsData = {
  threshold: number
  product: Payload.Product | null
}

export type ChannelGiftMap = Array<GiftsData>

export interface BonusCheckInput {
  subtotal: number
  currentLevel: number
}

export type BonusToUpdateData = {
  action: "add" | "remove" | null
  items: GiftsData[]
  level: number
}

export type GiftHookReturnData = {
  giftsEnabled: boolean
  giftData: ChannelGiftMap
  getAllLevels: (currency: Currency) => GiftsData[] | null
  getCurrentLevel: (subtotal: number, currency: Currency) => number | null
  getLevel: (level: number, currency: Currency) => GiftsData | null
  bonusToUpdate: ({
    currentLevel,
    subtotal,
  }: BonusCheckInput) => BonusToUpdateData
}
