import { countriesData, CountryData } from "./data"

export const defaults = {
  currencyCode: "USD",
  currencySymbol: "$",
  dir: "ltr",
  lang: "en_US",
  locale: "en",
  priceDecimals: 2,
} as CountryData

export const getCountry = (country: string) => {
  const countryData = countriesData.has(country.toLowerCase())
  if (!countryData) {
    return "us"
  }
  return country
}

export const getCountryData = (country: string) => {
  const countryData = countriesData.get(country.toLowerCase())
  if (!countryData) {
    return defaults
  }
  return countryData
}

export const getLocaleFromCountry = (country: string) => {
  const countryData = countriesData.get(country.toLowerCase())
  if (!countryData) {
    return {
      locale: defaults.locale,
    }
  }
  return {
    locale: countryData.locale,
  }
}

export const getCurrencySymbolByCurrencyCode = (currencyCode: string) => {
  let currencySymbol = null
  countriesData.forEach((country) => {
    if (country.currencyCode === currencyCode) {
      currencySymbol = country.currencySymbol
    }
  })

  return currencySymbol
}

export const getCountryByCurrencyCode = (currencyCode: string) => {
  let country = null
  countriesData.forEach((value, key) => {
    if (value.currencyCode === currencyCode) {
      country = key
    }
  })

  return country
}

export const getCountryCodefromCountryName = (input: string) => {
  let country
  switch (input) {
    case "Australia":
      country = "au"
      break
    case "Austria":
    case "Belgium":
    case "Bulgaria":
    case "Croatia":
    case "Cyprus":
    case "Czechia":
    case "Denmark":
    case "Estonia":
    case "Finland":
    case "Greece":
    case "Hungary":
    case "Italy":
    case "Latvia":
    case "Lithuania":
    case "Luxembourg":
    case "Malta":
    case "Netherlands":
    case "The Netherlands":
    case "Poland":
    case "Portugal":
    case "Romania":
    case "Slovakia":
    case "Slovenia":
    case "Spain":
    case "Sweden":
      country = "eu"
      break
    case "Germany":
      country = "de"
      break
    case "France":
      country = "fr"
      break
    case "New Zealand":
      country = "nz"
      break
    case "Ireland":
      country = "ie"
      break
    case "United Kingdom":
      country = "gb"
      break
    case "Canada":
      country = "ca"
      break
    case "Switzerland":
      country = "ch"
      break
    case "United States":
    default:
      country = "us"
      break
  }
  return country
}
