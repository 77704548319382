"use client"
import { useState, useEffect } from "react"

export const useLocalStorage = <T>(
  key: string,
  initialValue: T
): [T, (value: T) => void] => {
  const [storedValue, setStoredValue] = useState<T>(initialValue)

  // Needed to avoid hydration mismatch
  useEffect(() => {
    try {
      const item =
        window.localStorage && window.localStorage.hasOwnProperty("getItem")
          ? window.localStorage.getItem(key)
          : null
      // Parse JSON if possible, else return as string
      let valueToStore
      try {
        valueToStore = JSON.parse(item as string) || initialValue
      } catch (e) {
        valueToStore = item || initialValue
      }
      setStoredValue(valueToStore)
    } catch (error) {
      console.error(error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key])

  const setValue = (value: T) => {
    try {
      setStoredValue(value)
      // Stringify JSON if possible, else store as string
      let valueToStore
      try {
        valueToStore = JSON.stringify(value)
      } catch (e) {
        valueToStore = value as unknown as string
      }
      window.localStorage.setItem(key, valueToStore)
    } catch (error) {
      console.error(error)
    }
  }

  return [storedValue, setValue]
}
