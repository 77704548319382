"use client"
import { useEffect } from "react"

/**
 * Hook that alerts clicks outside of the passed ref
 */
export const useOutsideClickCapture = (
  ref: React.MutableRefObject<any>,
  callback: () => {} | void
) => {
  useEffect(() => {
    const handleClickOutside = (e: any) => {
      if (ref.current && !ref.current.contains(e.target)) {
        callback()
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref])
}
