"use client";

import { useEffect, useState } from "react";
export const useDocumentVisible = () => {
  const [documentVisible, setDocumentVisible] = useState("visible");
  useEffect(() => {
    if (!document) {
      setDocumentVisible("visible");
    }
    const handleVisibilityChange = () => setDocumentVisible(document.visibilityState);
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => document.removeEventListener("visibilitychange", handleVisibilityChange);
  }, []);
  return documentVisible === "visible";
};