"use client"
import { useSyncExternalStore } from "react"

/**
 * useDocumentReady React custom hook
 * This hook is used to check if the document is ready
 *
 * @param active - Enables/Disables externally the checkpoint
 *
 * @example ```ts
 * useCheckpoint(true);
 * ```
 */
export const useDocumentReady = () => {
  const subscribe = (callback: () => void) => {
    window.addEventListener("load", callback)
    return () => {
      window.addEventListener("load", callback)
    }
  }

  const getSnapshot = () => {
    if (window) return document.readyState === "complete" ? true : false
  }

  const getServerSnapshot = () => {
    return true // Always show "complete" for server-generated HTML
  }

  return useSyncExternalStore(subscribe, getSnapshot, getServerSnapshot)
}
